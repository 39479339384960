import React from 'react';
import {
//	Link,
	graphql,
	useStaticQuery
} from 'gatsby';
import Img from 'gatsby-image';
import settings from '../settings';
import Layout from '../components/Layout';
import Hero from './index/Hero';
import Section from './index/Section';
import SectionImage from './index/SectionImage';
import ServiceTypesGrid from './index/ServiceTypesGrid';
//	import SectionLists from '../components/SectionLists';
//	import ListBullet from '../components/ListBullet';

import BackgroundImage from 'gatsby-background-image';

import Reveal from '../components/Reveal';

import '../styles/pages/home.scss';
import BannerCompanyName from './index/BannerCompanyName';

import ContactForm from './contact/Form';

export default function() {
	const section_height = '440px';

/*	
	const styles_li = {
		lineHeight: '70px',
	};
*/
	
	const styles_SectionImage_h1 = {
		background: 'rgba(255, 255, 255, 0.5)',
		borderTopLeftRadius: '50px',
		borderBottomRightRadius: '5px',
		boxShadow: '1px 1px 4px 0px #222',
		color: '#333',
//		color: settings.color.font.dark,
//		fontStyle: 'oblique',
    	fontWeight: '100',
//		letterSpacing: '3px',
		padding: '12px 24px',
		textShadow: 'black 1px 0px 0px',
	};
	
	const {
		imageMobile_hero,		imageDesktop_hero,
		imageMobile_handshake,	imageDesktop_handshake,
		imageMobile_pebbles,	imageDesktop_pebbles,
	} = useStaticQuery(
		graphql`
		  query {

			imageMobile_hero: file(relativePath: { eq: "pages/index/istock.homefront.01.jpg" }) {
			  childImageSharp {
				fluid(quality:20, maxWidth:320, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_hero: file(relativePath: { eq: "pages/index/istock.homefront.01.jpg" }) {
			  childImageSharp {
				fluid(quality:50, maxWidth:768, maxHeight:640, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_handshake: file(relativePath: { eq: "pages/index/istock.handshake.jpg" }) {
			  childImageSharp {
				fluid(quality:60, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_handshake: file(relativePath: { eq: "pages/index/istock.handshake.jpg" }) {
			  childImageSharp {
				fluid(quality:60, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_pebbles: file(relativePath: { eq: "pages/index/BannerCompanyName/istock.pebbles.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_pebbles: file(relativePath: { eq: "pages/index/BannerCompanyName/istock.pebbles.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

		  }
		`
	  );

	const image_sources = {
		hero: [
			imageMobile_hero.childImageSharp.fluid,
			{
				...imageDesktop_hero.childImageSharp.fluid,
				media: `(min-width: 491px)`,
			},
		],
		handshake: [
			imageMobile_handshake.childImageSharp.fluid,
			{
				...imageDesktop_handshake.childImageSharp.fluid,
				media: `(min-width: 491px)`,
			},
		],
		pebbles: [
			imageMobile_pebbles.childImageSharp.fluid,
			{
				...imageDesktop_pebbles.childImageSharp.fluid,
				media: `(min-width: 491px)`,
			},
		],
	};

	return (
	<Layout>

			<BannerCompanyName/>

			<div>
				<Img fluid={image_sources.hero} alt="Beautiful Homefront" style={{
					height: '100vh',
					width: '100%',
				}} />
			</div>

			<Hero height={section_height} />

			<SectionImage
				showVerticalBar={true}
				imageSources={image_sources.handshake}
				imageAlt="Workers Shaking Hands"
			>
				<h2 style={styles_SectionImage_h1}>Services</h2>
			</SectionImage>
			<ServiceTypesGrid/>
{
/*
			<SectionLists height={section_height} style={{
				backgroundColor: settings.color.bg.dark,
				color: settings.color.font.light,
			}}>
				<div className="col-lg-5 col-md-6 col-sm-11 offset-1 offset-lg-1 offset-sm-1 align-self-center"
					style={{
						fontSize: '22px',
						fontWeight: '100',
						whiteSpace: 'nowrap',
					}}
				>
					<ul style={{
						listStyle: 'none',
						marginBottom: '0px',
					}}>
						<li style={styles_li}><ListBullet/><Link to="/hardscapes/#walls">Stone Hardscapes</Link></li>
						<li style={styles_li}><ListBullet/><Link to="/veneer/">Stone Veneer</Link></li>
						<li style={styles_li}><ListBullet/><Link to="/landscaping/#drainage">Soil Drainage</Link></li>
					</ul>
				</div>
				<div className="col-lg-5 col-md-6 col-sm-11 offset-1 offset-lg-1 offset-sm-1 align-self-center"
					style={{
						fontSize: '22px',
						fontWeight: '100',
						whiteSpace: 'nowrap',
					}}
				>
					<ul style={{
						listStyle: 'none',
						marginBottom: '0px',
					}}>
						<li style={styles_li}><ListBullet/><Link to="/landscaping/#mowing">Mowing</Link></li>
						<li style={styles_li}><ListBullet/><Link to="/planting/">Planting</Link></li>
						<li style={styles_li}><ListBullet/><Link to="/landscaping/#grading">Grade Leveling</Link></li>
					</ul>
				</div>
			</SectionLists>
*/
}
{
/*
			<SectionImage
				height={section_height}
				imageSources={image_sources.hedgesPath}
				imageAlt="Areal View of Stone Patio & Walkway"
			>
				<h2 style={styles_SectionImage_h1}>Stone Styles</h2>
			</SectionImage>
			<StoneTypesGrid/>
*/
}

			<Section height={section_height} textHeading="Quality">
				Decades of experience in our hands
			</Section>
			<Section height={section_height} textHeading="Longevity">
				"<i>Your eaves will have fallen to earth before one stone on this wall moves.</i>" <span style={{color:settings.color.font.dark,whiteSpace:'nowrap'}}>- Customer</span>
			</Section>
			<Section height={section_height} textHeading="Beauty">
				"<i>I don't divide architecture, landscape and gardening; to me they are one.</i>" <span style={{color:settings.color.font.dark,whiteSpace:'nowrap'}}>- Luis Barragan</span>
			</Section>

			<BackgroundImage alt="White Pebbles" fluid={image_sources.pebbles}>
				<div className="container pt-5">
					<div className="col">
						<div className="row">
							<div className="col-12 text-center">
								<Reveal><h4>Contact Us</h4></Reveal>
							</div>
							<ContactForm className="m-auto" />
						</div>
					</div>
				</div>
			</BackgroundImage>

	</Layout>
	);
}