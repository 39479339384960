import React from 'react';
import settings from '../../../settings';
import Reveal from '../../../components/Reveal';
import BackgroundImage from 'gatsby-background-image';

//	https://www.istockphoto.com/photo/handshake-gm183286204-14605288
//	https://image.shutterstock.com/image-photo/modern-garden-design-terrace-top-600w-572451373.jpg
//	https://image.shutterstock.com/image-photo/natural-landscaping-panorama-home-garden-600w-639534952.jpg

//	FYI:
//	bgImageMobile,bgImageDesktop are instances returned by the graphql query from related to the plugin dependencies of gatsby-image

const Component = function({imageSources,children,showVerticalBar,imageAlt}) {

	return (
		<div style={{position:'relative'}}>
			<BackgroundImage alt={imageAlt} fluid={imageSources} style={{
				height: '100%',
				width: '100%',
				filter: 'blur(2px)',
				position: 'absolute',
			}} />
			<Reveal>
				<div className="container py-7">
					<div className="column">
						<div className="row pl-5 pl-md-6">
							{children}
						</div>
					</div>
				</div>
			</Reveal>
			<Reveal>
				{showVerticalBar?<VerticalBar/>:null}
			</Reveal>
		</div>
	);
}

export default Component;

function VerticalBar(){
	return (
		<div style={{
			backgroundColor: settings.color.bg.light,
			borderRadius: '50%',
			boxShadow: '0px -2px 6px 5px '+settings.color.bg.dark,
			transition: 'width .75s linear',
			transitionDelay: '1.25s',
			height: '75px',
			width: '4px',
			position: 'absolute',
				bottom: '-50px',
				left: '14%',
			zIndex: '99',
		}}></div>
	);
}