import React from 'react';
import Reveal from '../../../components/Reveal';
import {Link,graphql,useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';

export default function() {

	const {
		imageMobile_hardscapes,		imageDesktop_hardscapes,
		imageMobile_veneer,			imageDesktop_veneer,
		imageMobile_drainage,		imageDesktop_drainage,
		imageMobile_mowing,			imageDesktop_mowing,
		imageMobile_planting,		imageDesktop_planting,
		imageMobile_lawnLeveling,	imageDesktop_lawnLeveling,
	} = useStaticQuery(
		graphql`
		  query {

			imageMobile_hardscapes: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.hardscapes.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_hardscapes: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.hardscapes.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:512, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_veneer: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.veneer.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_veneer: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.veneer.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:512, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_drainage: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.drainage.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_drainage: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.drainage.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:512, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_mowing: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.mowing.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_mowing: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.mowing.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:512, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_planting: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.planting.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_planting: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.planting.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:512, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_lawnLeveling: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.lawnLeveling.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_lawnLeveling: file(relativePath: { eq: "pages/index/ServiceTypesGrid/istock.lawnLeveling.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:512, maxHeight:220, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

		  }
		`
	  );

  return (
		<div style={{

//			background: 'rgb(180,180,180)',
//			background:	'linear-gradient(180deg, rgba(180,180,180,1) 0%, rgba(255,255,255,0.22872899159663862) 20%)',

//			background: 'rgb(0,0,0)',
			background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(180,180,180,1) 25px, rgba(255,255,255,0.22872899159663862) 150px)',

			paddingTop: '70px',
		}}>
			<div className="container">
				<div className="column">
					<div className="row">

						<Tile
							imageMobile={imageMobile_hardscapes}
							imageDesktop={imageDesktop_hardscapes}
							imageAlt="Hardscapes"
							href="/hardscapes/"
						>Hardscapes</Tile>
						<Tile
							imageMobile={imageMobile_mowing}
							imageDesktop={imageDesktop_mowing}
							imageAlt="Mowing"
							href="/landscaping/"
						>Mowing</Tile>
						<Tile
							imageMobile={imageMobile_planting}
							imageDesktop={imageDesktop_planting}
							imageAlt="Planting"
							href="/planting/"
						>Planting</Tile>
						<Tile
							imageMobile={imageMobile_lawnLeveling}
							imageDesktop={imageDesktop_lawnLeveling}
							imageAlt="Lawn Leveling"
							href="/landscaping/#grading"
						>Lawn Leveling</Tile>
						<Tile
							imageMobile={imageMobile_drainage}
							imageDesktop={imageDesktop_drainage}
							imageAlt="Drainage"
							href="/landscaping/#drainage"
						>Drainage</Tile>
						<Tile
							imageMobile={imageMobile_veneer}
							imageDesktop={imageDesktop_veneer}
							imageAlt="Stone Veneer"
							href="/veneer/"
						>Stone Veneer</Tile>

					</div>
				</div>
			</div>
		</div>
	);
}

function Tile({children,imageMobile,imageDesktop,imageAlt,href}) {

	const sources = [
		imageMobile.childImageSharp.fluid,
		{
		...imageDesktop.childImageSharp.fluid,
			media: `(min-width: 768px)`,
		},
	];

	return (
		<div className="col-lg-4 col-md-6 col-sm-12 font-weight-lighter" style={{
			color		:	'black',
			marginBottom:	'30px',
		}}>
			<Reveal>
				<Link to={href}>
					<Img fluid={sources} alt={imageAlt} style={{
						borderRadius: '20px',
						boxShadow: '2px 2px 3px 0px #222',
						height: '200px',
						width: '100%',
					}} />
					<div style={{textAlign:'center',color:'black',letterSpacing:'4px'}}>{children}</div>
				</Link>
			</Reveal>
		</div>
	);
}