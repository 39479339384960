import React from "react";
import settings from '../../../settings';
import Reveal from '../../../components/Reveal';

export default function({height}) {
  return (
		<div className="py-7" style={{
			backgroundColor: settings.color.bg.light,
			position: 'relative',
			width: '100%',

		}}
		>
		<Reveal>
			<div style={{
				bottom: '100px',
				color: settings.color.font.dark,
				left: '0',
				maxWidth: '600px',
				paddingLeft: '10%',
				paddingRight: '2%',
//				position: 'absolute',
			}}>
				<div style={{
					marginBottom: '30px',
				}}
				>Our Work</div>
				<h1 style={{
					fontSize: '32px',
					fontWeight: '100',
					letterSpacing: '0.01px',
				}}>Building authentic and iconic stone masonry to stand against time</h1>
			</div>
		</Reveal>
		</div>
	);
}
