import React from 'react';
import {graphql,useStaticQuery} from 'gatsby';

export default function(){

	const {
		imageMobile
	} = useStaticQuery(
		graphql`
		  query {

			imageMobile: file(relativePath: { eq: "pages/index/BannerCompanyName/istock.pebbles.jpg" }) {
			  childImageSharp {
				fluid(quality:30, maxWidth:320, maxHeight:200) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

		  }
		`
	);

//	console.log(imageMobile.childImageSharp.fluid.srcSetWebp);

	const image_url	=	imageMobile.childImageSharp.fluid.srcSetWebp.split(',')[1].slice(0,-5);
//	console.log(image_url);

	return (
		<div className="BannerCompanyName">
			<div className="circle" ></div>
			<div className="align-middle text" style={{
				backgroundImage: `url(${image_url})`,
			}}>Blackstone</div>
		</div>
	);
}
