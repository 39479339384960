import React from 'react';
import settings from '../../../settings';

import '../../../styles/components/ContactForm.scss';

import BootstrapInput from '../../../components/Bootstrap/Input';
import BootstrapSelect from '../../../components/Bootstrap/Select';
import BootstrapTextArea from '../../../components/Bootstrap/TextArea';
import BootstrapButton from '../../../components/Bootstrap/Button';
import RecaptchaDisclaimer from '../../../components/RecaptchaDisclaimer';

//	https://www.google.com/u/1/recaptcha/admin/site/435639217
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function ({className,style}) {

	const { executeRecaptcha } = useGoogleReCaptcha();

	const options_work_scope	=	settings.select_options_services;

	const options_timeline	=	[
		{value:'within_1_week',text:'I need it yesterday'},
		{value:'within_1_month',text:'Within a month'},
		{value:'within_2_month',text:'Within 2 months'},
		{value:'within_3_month',text:'Within 3 months'},
		{value:'not_this_season',text:'Sometime next season 🤷🏼‍♂️'},
	];

	const toggleForm = function(event,form_enabled){
		const form		=	event.target;
		const fields	=	form.querySelector('.fields');
		if( form_enabled ){
			fields.classList.remove('disabled');
			form.submit.disabled	=	false;
		}else{
			fields.classList.add('disabled');
			form.submit.disabled	=	true;
		}
	};

	const onSubmit = async function(event){
		event.persist();
		event.stopPropagation();
		event.preventDefault();

//		Disable Form during processing
		toggleForm(event,false);

//		Get Recaptcha Token
		const recaptcha_response_token	=	await executeRecaptcha('contact');
	//	console.log('Recaptcha Result',recaptcha_response_token);

//		Format Payload Data
		const form		=	event.target;
		const form_data	=	new FormData(form);

		const form_json	=	{};
		form_json.recaptcha_response_token	=	recaptcha_response_token;
		form_data.forEach(function(value,key){form_json[key]=value;});

//		Shared response variable
		let response;

//	---------------
//	Check Recaptcha
//	---------------
		console.log('Bot protection: Contact');
		response	=	await window.fetch(settings.endpoints.forms.verify_recaptcha,{
			body	:	JSON.stringify(form_json),
			cache	:	'no-store',
			headers	:	{
				'Content-Type'	:	'application/json',
			},
			method	:	'POST',
		//	mode	:	'cors',
		})
	//	.then(async function(res){console.log(await res.text());return res;})	//	Debugging Only
		.then(response=>response.json())
		.then(response=>{
			console.log(response);	//	Debugging Only
			if( response.success ){
				//	All good; let them continue.
			}else{
				throw new Error("We're sorry, you're being caught by our bot-detection system. Repeated attempts will not succeed.");
			}

			return true;

		})
		.catch(function(error){
			console.log(error,error.stack);
			const message_error	=	error.message || 'An error occurred with our system. Repeated attempts will not succeed.';
			const message	=	`🚨 ${message_error}`;
			alert(message);
			return false;
		})

		if( !response ){
			toggleForm(event,true);
			return false;	//	Breakpoint if failed recaptcha.
		}

//	-----------
//	Submit Form
//	-----------
		console.log('Submitting Form: Contact');
		await window.fetch(settings.endpoints.forms.submit_contact,{
			body	:	JSON.stringify(form_json),
			cache	:	'no-store',
			headers	:	{
				'Content-Type'	:	'application/json',
			},
			method	:	'POST',
		//	mode	:	'cors',
		})
		.then(async(response)=>{
			const json			=	await response.json();
			console.log(response,json);	//	Debugging Only
			if( 200<=response.status && response.status<300 ){
				alert("👍 Thanks! We'll be in contact within 1-2 business days.");
			}else{
				throw new Error(response.message);
			}
		})
		.catch(function(error){
			const message_error	=	error.message || 'An error occurred with our system. Repeated attempts will not succeed.';
			const message	=	`🚨 ${message_error}`;
			alert(message);
		})
		.finally(function(){
			//	Enable Form
			toggleForm(event,true);
			//	Clear Fields
			const form		=	event.target;
			form.reset();
		})
		;
		return false;
	}

	return (
		<form className={'form-contact '+className} style={{
			paddingBottom: '40px',
			...style
		}} onSubmit={onSubmit}>
			<div className="fields">
				<BootstrapInput name="name" label="🧍‍♂️ Name" required />
				<BootstrapInput name="email" label="📧 Email" type="email" required />
				<BootstrapInput name="phone" label="📱 Phone #" type="tel" required />
				<BootstrapSelect name="work_type" label="Scope of work" required options={options_work_scope} />
				<BootstrapSelect name="desired_start" label="Start when" required options={options_timeline} />
				<BootstrapTextArea name="message" label="Message" rows="3" optional />
			</div>
			<BootstrapButton name="submit" label="Submit" type="submit" value="Submit" />
			<RecaptchaDisclaimer/>
		</form>
	);
}