import React from "react";
import settings from '../../../settings';
import Reveal from '../../../components/Reveal';

export default function({height,textHeading,children}) {
  return (
		<div className="pt-2 pb-5 pt-md-0 pb-md-6" style={{
			backgroundColor: settings.color.bg.dark,
			borderTop: '1px solid '+settings.color.font.dark,
			position: 'relative',
			width: '100%',

		}}
		>
		<Reveal>
			<div
				className="col-11 col-md-6 offset-1 offset-1"
				style={{
					paddingTop: '12%',
				}}
			>
				<div style={{
					color: settings.color.font.dark,
					marginBottom: '30px',
					position: 'relative',
				}}
				>
					<div style={{
						fontSize: '44px',
						fontWeight: '100',
					}}>{textHeading}</div>
					<div style={{
						backgroundColor: settings.color.bg.light,
						marginTop: '70px',
						marginBottom: '20px',
						transition: 'width .75s linear',
						transitionDelay: '1.25s',
						height: '2px',
						width: '175px',
					}}></div>
					<div style={{
						color: settings.color.font.light,
						fontSize: '20px',
						fontWeight: '300',
						letterSpacing: '0.01px',
					    lineHeight: '32px',
					}}>{children}</div>
				</div>
			</div>
		</Reveal>
		</div>
	);
}
